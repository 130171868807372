import React from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Container,
  Avatar,
} from "@mui/material";

const HomeMinistries = () => {
  const ministries = [
    {
      icon: "/images/hello.jpg",
      title: "L'appel",
      body: "L'appel de l'Apôtre est confirmé par des signes et des miracles ainsi que pae le salut des âmes.",
    },
    {
      icon: "/images/chat-bubble.jpg",
      title: "Le message",
      body: "Le message authentique qu'il a reçu est basé sur la sainte doctrine de la Parole de Dieu, le sacerdoce, la délivrance et le salut des âmes.",
    },
    {
      icon: "/images/church.jpg",
      title: "L'onction",
      body: "L'onction qu'il a reçu se reprend avec efficacité sur l'église et faisant beaucoup des biens à plusieurs.",
    },
    {
      icon: "/images/users.jpg",
      title: "Le fardeau",
      body: "Son plus grand fardeau est le salut des âmes ainsi que d'amener chaque chrétien à la connaissance de la Parole de Dieu> La transformation de la jeunesse chrétienne fait parti de son engagement.",
    },
  ];
  return (
    <Box sx={{ backgroundColor: "#111", p: 5, color: "white" }}>
      <Container>
        <Typography variant="h4" textAlign={"center"}>
          Le Ministère de l'Apôtre
        </Typography>
        <Typography
          textAlign={"center"}
          sx={{ px: { xs: 3, md: 30 }, my: 2, fontSize: { xs: 13, md: 16 } }}
        >
          Le ministère de l'Apôtre est un appel authentique qui a fait ses
          preuves au travers de son message et de son onction.
        </Typography>
        <Grid container sx={{ mt: 3 }} spacing={2}>
          {ministries.map((ministry) => (
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  textAlign: "center",
                  borderRadius: 6,
                  p: 5,
                }}
              >
                <Box
                  flexDirection={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{ my: 2, display: "flex" }}
                >
                  <Avatar src={ministry.icon} sx={{ width: 80, height: 80 }} />
                </Box>
                <Typography variant="h5">{ministry.title} </Typography>
                <Typography sx={{ my: 2, fontSize: { xs: 13, md: 16 } }}>
                  {ministry.body}
                </Typography>
                <Typography sx={{ my: 2 }}>
                  <Button component="a" href="/emi" variant="contained">
                    En savoir plus
                  </Button>
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default HomeMinistries;
