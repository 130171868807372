import React from "react";
import { Grid, Container, Typography, Box, Button } from "@mui/material";

const HomeAbout = () => {
  return (
    <Container sx={{ py: 8 }}>
      <Grid container direction={"row-reverse"} spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography textAlign={"center"}>
            <img
              src="/images/photo3.jpeg"
              width={"100%"}
              style={{ borderRadius: 8 }}
            />
          </Typography>
        </Grid>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          item
          xs={12}
          md={6}
        >
          <Box sx={{ p: 3 }}>
            <Typography variant="h4">
              Qui est l'apôtre Leopold Mutombo Kalombo?
            </Typography>
            <Typography textAlign={"justify"} sx={{ my: 2 }}>
              L’Apôtre Léopold MUTOMBO est une bénédiction pour le Corps de
              Christ. Un dispensateur pour l’église de ce temps, un homme que
              Dieu a suscité et oint de manière très spéciale dans cette
              génération pour influencer des ministres de l’évangile, amener les
              chrétiens à élever leurs spiritualités en Christ et préparer
              l’Église du Seigneur à l’enlèvement.
            </Typography>
            <Typography sx={{ my: 3 }}>
              <Button component="a" href="/a-propos" variant="contained">
                En savoir plus
              </Button>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomeAbout;
