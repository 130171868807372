import React from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Container,
  alpha,
  Divider,
  useTheme,
} from "@mui/material";

const HomeOeuvres = () => {
  const theme = useTheme();
  const oeuvres = [
    {
      title: "Les campagnes d'évangélisation",
      image: "/images/evangelisation.jpg",
      content:
        "Gagner un grand nombre à Christ a toujours été le grand fardeau qui anime le cœur de l'Apôtre Léopold MUTOMBO KALOMBO",
    },
    {
      title: "La visitation divine",
      image: "/images/visitation.jpg",
      content:
        "Cette grande dispensation a en son registre plusieurs témoignages qui dépassent l'entendement humain, en l'occurence, des personnes nées sans sexe retrouvent leurs identités, des morts qui ressuscitent même en contact avec l'effigie de l'Oint de Dieu l'Apôtre Léopold MUTOMBO",
    },
    {
      title: "La destruction des autels",
      image: "/images/autels.jpg",
      content:
        "La Destruction des Autels est une grande révélation que le Seigneur Jesus-Christ a aaccordée à son serviteur l'apôtre Léoplod MUTOMBO. Dans ce programme, Dieu a plongé son peuple dans une sorte de triatement prolongé pendant une période.",
    },
  ];
  return (
    <Box
      sx={{
        p: 8,
        mb: { md: 20, xs: 25 },
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: theme.palette.primary.main,
        height: 600,
        color: "white",
      }}
    >
      <Box>
        <Typography textAlign={"center"} variant="h4">
          Oeuvres évangéliques
        </Typography>
        <Typography
          sx={{ my: 1, fontSize: { xs: 13, md: 16 } }}
          textAlign={"center"}
        >
          L'appel de l'Apôtre Léopold MUTOMBO KALOMBO est confirmé par des
          signes et des miracles ainsi que par le salut des âmes.
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#111",
          p: { xs: 3, md: 5 },
          mt: 5,
          borderRadius: 6,
          height: 600,
          overflow: "scroll",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: { md: "flex", xs: "none" },
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography>PARCOURIR</Typography>
              <Button
                sx={{ color: "white", borderColor: "white" }}
                variant="outlined"
              >
                Voir tout
              </Button>
            </Box>
          </Grid>
          {oeuvres.map((oeuvre) => (
            <Grid container item xs={12}>
              <Grid item xs={12} md={3}>
                <Typography>
                  <img src={oeuvre.image} width={"100%"} />
                </Typography>
              </Grid>
              <Grid
                container
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                item
                xs={12}
                md={7}
              >
                <Box sx={{ px: 3 }}>
                  <Typography sx={{ my: 1 }} variant="h5">
                    {oeuvre.title}
                  </Typography>
                  <Typography
                    sx={{ my: 1, fontSize: { xs: 10, md: 13 } }}
                    color={"whitesmoke"}
                  >
                    {oeuvre.content}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                container
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                xs={12}
                md={2}
              >
                <Box>
                  <Button component="a" href="/oeuvres" variant="contained">
                    {" "}
                    Lire plus{" "}
                  </Button>
                </Box>
              </Grid>
              <hr
                style={{
                  width: "100%",
                  marginTop: 20,
                  backgroundColor: "#333",
                  color: "#333",
                  borderColor: "#333",
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default HomeOeuvres;
