import React from "react";
import Breadcrumb from "../../components/includes/Breadcrumb";
import { Box, Container, Grid, Typography } from "@mui/material";
import HomeContact from "../../components/home/Contact";
import OurShop from "../../components/includes/Shop";

const Business = () => {
  return (
    <div>
      <Breadcrumb title={"Business Class"} />
      <Container sx={{ my: 5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Container>
              <img src="/images/pastorale.JPG" width={"100%"} alt="business" />
            </Container>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container>
              <Typography>
                Dieu veut que nous venions au monde pour réaliser le but le plus
                élevé qu’il a préparé ici sur terre. Ainsi que sa parole déclare
                qu’il a préparé d’avances les bonnes choses afin que dès qu’on
                arrive qu’on les trouve et qu’on les expérimente. Mais il y a un
                grand contraste inexplicable entre les promesses divines et le
                vécu quotidien.
              </Typography>
              <Typography sx={{ my: 2 }}>
                L’Apôtre Léopold Mutombo K. s’adresse à tout être humain quelque
                soit son domaine d’activité. Mais surtout, il s’adresse au
                troisième fils d’Abraham, au chrétien : <br />
              </Typography>
            </Container>
          </Grid>
          <Grid item xs={12}>
            <Container>
              <Typography sx={{ my: 2 }}>
                - Qui n’a jamais su que la richesse est compatible à la foi
                chrétienne. Un chrétien à la mentalité limitée, longtemps
                convaincu que le succès matériel-financier ne pouvait s’obtenir
                par la foi. Véhiculé au cours des âges l’idée d’un christianisme
                modeste et simpliste, cette conception ne se joint pas à la
                pensée de Dieu sur la valeur accordée à l’être humain, surtout à
                son enfant par rapport aux autres créatures. <br />
                Dieu veut que le chrétien entreprenne et que connaisse la
                multiplication, le succès, la grandeur et la domination !
              </Typography>
              <Typography sx={{ my: 2 }}>
                - Qui ne connais que d’échecs sur tout ce qu’il entreprend.
                L’Apôtre fourni les stratégiques pour changer ses échecs en
                réussite.
              </Typography>
              <Typography sx={{ my: 2 }}>
                - Qui est entrain de connaître du succès dans ses entreprises.
                Ce message le maintien dans le succès et le pousse d’aller de
                succès en succès jusqu’à dominer le secteur de son activité !
              </Typography>
              <Typography sx={{ my: 2 }}>
                Cette série d’enseignement remet non seulement en question le
                christianisme modeste et simpliste, mais fait assoir le chrétien
                en tant que 3ème Fils d’Abraham sur le train de la
                multiplication, du succès, de la grandeur et de la domination !
              </Typography>
              <Typography sx={{ my: 2 }}>
                Ce message révolutionne réellement la mentalité du chrétien et
                met à sa disposition les clés, les stratégies dégageant une
                onction exceptionnelle pour le faire basculer de la pauvreté à
                la richesse, des échecs aux réussites, de succès à la domination
                dans son domaine spécifique.
              </Typography>
              <Typography sx={{ my: 2 }}>
                « Business class » veut faire une nouvelle génération de
                chrétien dominant sur son héritage céleste et sur son héritage
                terrestre ! <br />
                Déterminé à développer une grande foi aux dons excellents de
                Dieu dans le chef du 3eme fils d’Abraham et engagé à susciter
                des leaders chrétiens possédant de grandes bénédictions en
                rapport avec les promesses divines, l’apôtre Léopold Mutombo a
                tenu plusieurs conférences Business Class non seulement à
                Kinshasa mais également sur d’autres contrées : à
                Libreville-Gabon et à Abidjan-Côte d’Ivoire. Ecclésiaste 5:18
              </Typography>
            </Container>
          </Grid>
        </Grid>
      </Container>
      <Box sx={{ backgroundColor: "whitesmoke", py: 5 }}>
        <Container>
          <OurShop />
        </Container>
      </Box>
      <HomeContact />
    </div>
  );
};

export default Business;
