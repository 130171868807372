import React from "react";
import Breadcrumb from "../../components/includes/Breadcrumb";
import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import OurShop from "../../components/includes/Shop";
import country from "country-list-js";

const BecomePartner = () => {
  const countries = country.ls("name");
  return (
    <div>
      <Breadcrumb title={"Devenir partenaire"} />
      <form>
        <Container sx={{ py: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}></Grid>
            <Grid container spacing={1} item xs={12} md={6}>
              <Grid item xs={12}>
                <Typography sx={{ my: 2 }} variant="h3">
                  Remplissez le formulaire
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField label="Nom" fullWidth variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Prénom" fullWidth variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <TextField label="E-mail" fullWidth variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Téléphone" fullWidth variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <TextField select label="Pays" fullWidth variant="outlined">
                  {countries.map((item) => (
                    <MenuItem value={item}>{item} </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  label="Souscription"
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value={"100-1000"}>100 USD - 1000 USD </MenuItem>
                  <MenuItem value={"+1000"}>+1000 USD </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField label="Fonction" fullWidth variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" fullWidth>
                  Envoyer
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </form>
      <Box sx={{ backgroundColor: "whitesmoke", py: 5 }}>
        <Container>
          <OurShop />
        </Container>
      </Box>
    </div>
  );
};

export default BecomePartner;
