import { Box, Typography } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import { books } from "../../data";

const OurShop = () => {
  const settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 3,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };
  return (
    <Box>
      <Typography variant="h3"> Visitez notre shop</Typography>
      <Box sx={{ my: 5 }}>
        <Slider {...settings}>
          {books.map((book) => (
            <Typography>
              <img src={book.image} alt="" width={"80%"} />
            </Typography>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default OurShop;
