import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Breadcrumb from "../../components/includes/Breadcrumb";
import HomeContact from "../../components/home/Contact";
import OurShop from "../../components/includes/Shop";

const Emi = () => {
  return (
    <div>
      <Breadcrumb title={"École du Ministère Internationale"} />
      <Container sx={{ my: 5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography sx={{ my: 2 }} variant="h3">
              École du Ministère Internationale (EMI)
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              <img src="/images/emi-1.jpg" alt="emi" width={"100%"} />
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container>
              <Typography textAlign={"justify"}>
                La grande École du Ministère Internationale, EMI en sigle, est
                un cadre qui permet à Dieu de préparer, d’encadrer, d’équiper,
                de former, de modeler ceux qu’il prépare à envoyer sur le champ
                de bataille. Elle est aussi un cadre évangélique de formation,
                d'apprentissage, de ressources, de remise à niveau des
                serviteurs et ministres de Dieu, ainsi que ceux aspirants au
                service divin.
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                Motivé par une grande vision axée sur la conquête du monde par
                l'évangile du salut en Jésus-Christ afin de susciter des
                générations de serviteurs et servantes de Dieu formés, oints et
                prêts pour relever les grands défis de l’évangile partout dans
                le monde ; le Patriarche Apôtre Léopold MUTOMBO met à la
                disposition des ministres de la Parole plus de 4 décennies de
                service dans le sacerdoce avec son Dieu.
              </Typography>
            </Container>
          </Grid>
          <Grid item xs={12}>
            <Container>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                Le Seigneur a mis sur le cœur de son Oint, l’Apôtre Léopold
                Mutombo Kalombo, le lourd fardeau de Partager avec le corps du
                Christ, la riche expérience de sa longue marche avec le Seigneur
                : dont près de 45 ans dans le ministère. D’un homme qui est
                devenu pour les autres après tâtonnements, échecs, humiliations,
                réussites et gloires une école. Un homme qui ouvre son cœur aux
                fins de partager en toute simplicité ses expériences
                surnaturelles avec Dieu.
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                Conscient de sa mission, de son fardeau et de sa vision pour
                l'œuvre ministérielle, hôte de la dite école, a toujours mis en
                évidence au cours de différentes éditions, ses propres
                expériences avec Dieu à cœur ouvert. En homme-école ayant
                traversé l'épreuve du temps, depuis son jeune âge dans le
                sacerdoce aux côtés de ses pères, du fils esclave à l'affranchi,
                de l'adopté à l'héritier est passé par le processus du
                brisement, de sacrifice et de l'Impactation pour à son tour se
                lire à haute voix, comme les écrits d' un livre ouvert à
                l'intention des ministres de Dieu.
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                Dans le soucis d'élever haut l'étendard de l'évangile pour le
                perfectionnement des saints, et à la mesure de la stature
                parfaite de christ, en léguant des véritables matériaux de
                construction durables comme fondement de l’Église pour les
                générations futures en maintenant la saine doctrine laissée par
                les apôtres ; la grande EMI fut d'abord établie de manière
                locale pour étendre finalement ses ramifications et expansion
                dans le temps et l'espace.
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                Bien avant son avènement, cette grande école de renommée
                planétaire est précédée de plusieurs instituts bibliques depuis
                1995, qui ont assuré la formation et remise à niveau sacerdotale
                de plusieurs serviteurs de Dieu comme l’École Biblique de Samuel
                (EBS), l’École de Tyranus, l’école de formation des disciples
                sans oublier les grandes pastorales tel que l’Action Réveil
                Moisson (AREM), la pastorale nationale du Congo (PANACO) et
                enfin, la célèbre École du Ministère Internationale.
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                Après plusieurs éditions d'une grande réussite à Kinshasa,
                Moanda, Lubumbashi, Libreville, Abidjan, Nairobi, Bogota,…
                assurément, plusieurs ministères connaissent de grands
                basculements.
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                L’apôtre Léopold MUTOMBO est un oint de YHWH, dans les paroles
                de qui se fond au quotidien, le Dieu de ses apparitions, en vue
                de manifester le surnaturel. Un homme qui a reçu une mission
                très particulière en ces temps de la fin : Celle de raser et de
                détruire les autels de l’ennemi afin de rebâtir un Autel saint
                pour le Seigneur Dieu qui revient bientôt. Il a reçu la grâce
                particulière de renforcer, à travers l’EMI, les capacités des
                ministres œuvrant dans le champ du Maître de la moisson, à
                savoir Jésus-Christ !
              </Typography>
            </Container>
          </Grid>
        </Grid>
      </Container>
      <Box sx={{ backgroundColor: "whitesmoke", py: 5 }}>
        <Container>
          <OurShop />
        </Container>
      </Box>
      <HomeContact />
    </div>
  );
};

export default Emi;
