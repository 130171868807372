import React from "react";
import Breadcrumb from "../../components/includes/Breadcrumb";
import { Box, Container, Grid, Typography } from "@mui/material";
import HomeContact from "../../components/home/Contact";
import OurShop from "../../components/includes/Shop";

const QuinzaineProphetique = () => {
  return (
    <div>
      <Breadcrumb title={"Quinzaine prophétique"} />
      <Container sx={{ my: 5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Container>
              <img
                src="/images/prophetique.jpg"
                width={"100%"}
                alt="quinzaine"
              />
            </Container>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container>
              <Typography textAlign={"justify"}>
                L'humanité est parvenue au soir de son histoire. Les deux
                semences, l'ivraie et le blé, sont parvenues à la maturité, tous
                les signes annoncés par le Seigneur Jésus Christ et ses
                prophètes s'accomplissent: L'homosexualité, la dépravation de
                mœurs, on entend parler de guerres et de bruits de guerres. (
                Matthieu 24:45)
              </Typography>
              <Typography textAlign={"justify"}>
                D’où l’importance de la quinzaine prophétique, cadre dans lequel
                l'apôtre Léopold Mutombo k. dispensent des enseignements
                prophétiques pour préciser à l'Église le temps auquel l'humanité
                est parvenue afin que chaque racheté se prépare à la rencontre
                du Seigneur.
              </Typography>
            </Container>
          </Grid>
          <Grid item xs={12}>
            <Container>
              <Typography textAlign={"justify"}>
                " Quel est donc le serviteur fidèle et prudent, que son maître a
                établi sur ses gens, pour leur donner la nourriture au temps
                convenable? " Matthieu 24:45
              </Typography>
              <Typography textAlign={"justify"}>
                Au cours de ces assises, l’homme de Dieu révèle de grands
                mystères en rapport avec la prophétie sur l’Église, l’Israël et
                les nations.
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                On y retrouve notamment les enseignements sur l'enlèvement de
                l’Église(1 Thessaloniciens 4:17) et le retour du Christ, la
                marque de la bête , le mystère de l'iniquité ( 2 Thessaloniciens
                2:7), le nouvel ordre mondial, le mystère de Babylone (
                apocalypse 17:3-7), etc
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                « L'espérance pour le Ciel reste l'objectif final pour tout
                chrétien né de nouveau. »
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                Ainsi pour y parvenir, pour mieux préparer son éternité , le
                chrétien doit s'enraciner dans la prière et dans la parole de
                Dieu.
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                « Il n’est pas bon pour un chrétien d’être uniquement
                évangélique, il lui faut également être prophétique pour être
                capable de lire les signes du temps de la fin. »
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                Hormis de grands moments de destruction des autels, une
                dispensation pendant laquelle Dieu se manifeste puissamment, le
                peuple de Dieu bénéficie de Quinzaines prophétiques
                régulièrement afin d’être prêt à monter dans les airs avec
                l’époux lorsqu’il paraîtra dans les airs.
              </Typography>
              <Typography>
                Hosanna! Gloire à l’agneau !!! Maranatha !!!
              </Typography>
            </Container>
          </Grid>
        </Grid>
      </Container>
      <Box sx={{ backgroundColor: "whitesmoke", py: 5 }}>
        <Container>
          <OurShop />
        </Container>
      </Box>
      <HomeContact />
    </div>
  );
};

export default QuinzaineProphetique;
