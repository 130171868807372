import React from "react";
import Breadcrumb from "../../components/includes/Breadcrumb";

const Missions = () => {
  return (
    <div>
      <Breadcrumb title={"Missions"} />
    </div>
  );
};

export default Missions;
