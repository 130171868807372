import React from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Container,
  alpha,
  useTheme,
  IconButton,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const HomeMultimedia = () => {
  const theme = useTheme();
  return (
    <Container sx={{ p: 5 }}>
      <Box>
        <Typography textAlign={"center"} sx={{ my: 2 }} variant="h4">
          Multimédia
        </Typography>
        <Typography sx={{ fontSize: { xs: 13, md: 16 } }} textAlign={"center"}>
          Découvrez les différents événements en vidéo
        </Typography>
      </Box>
      <Grid sx={{ my: 5 }} container>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            p: 3,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundImage: `linear-gradient(
        to bottom,
         ${alpha(theme.palette.primary.main, 0.3)},
         ${alpha(theme.palette.primary.main, 0.4)}
      ), url('/images/beneficiaires.jpg')`,
            height: { md: "auto", xs: 200 },
            borderBottomLeftRadius: { md: 8, xs: 0 },
            borderTopLeftRadius: { md: 8, xs: 0 },
          }}
          item
          xs={12}
          md={8}
        >
          <Box>
            <IconButton
              compponent="a"
              href="https://youtu.be/sPz9ChdZ9QU?si=IKAwLB_GdIoB1uOt"
              target="_blank"
              sx={{ backgroundColor: "white", p: 2 }}
              color="primary"
            >
              <PlayArrowIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid
          container
          sx={{
            p: 3,
            backgroundColor: theme.palette.primary.main,
            borderTopRightRadius: { md: 8, xs: 0 },
            borderBottomRightRadius: { md: 8, xs: 0 },
          }}
          item
          xs={12}
          md={4}
        >
          <Box>
            <Typography sx={{ my: 2, fontSize: 10 }} color={"white"}>
              PRÉDICATION
            </Typography>
            <Typography
              variant="h5"
              sx={{ my: 2, fontSize: { xs: 16, md: 20 } }}
              color={"white"}
            >
              LES BENEFICIAIRES DE LA LUMIERE DIVINE
            </Typography>
            <Typography sx={{ fontSize: { xs: 13, md: 16 } }} color={"white"}>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quod
              nisi esse culpa ipsam illo minima tempore doloribus facilis est
              repudiandae! Iste rerum nemo distinctio cumque minus quam quod
              nihil quae?
            </Typography>
            <Typography sx={{ my: 2 }}>
              <Button
                variant="outlined"
                sx={{ color: "white", borderColor: "white" }}
                compponent="a"
                href="https://www.youtube.com/@Leopoldmutombo"
              >
                Voir toutes les vidéos
              </Button>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomeMultimedia;
