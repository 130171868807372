import React from "react";
import Breadcrumb from "../../components/includes/Breadcrumb";
import { Box, Container, Grid, Typography } from "@mui/material";
import HomeContact from "../../components/home/Contact";
import OurShop from "../../components/includes/Shop";

const Destruction = () => {
  return (
    <div>
      <Breadcrumb title={"Déstruction des autels"} />
      <Container sx={{ my: 5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Container>
              <img src="/images/autels-1.JPG" width={"100%"} alt="autels" />
            </Container>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container>
              <Typography textAlign={"justify"}>
                La Destruction des Autels est une grande révélation que le
                seigneur Jésus-Christ a accordée à son serviteur l’apôtre
                Léopold MUTOMBO K.! Des grands moments où le Dieu de la Bible
                quitte se manifeste pour que l’humain se rapproche de lui! Dans
                ce programme, Dieu a plongé son peuple dans une sorte de
                traitement prolongé pendant une période : <br />
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                1. Pour révéler les mystères de Dieu <br />
                2. ⁠Pour maîtriser les mondes des spiritualités <br />
                3. ⁠Pour élever le niveau de spiritualité <br />
                4. ⁠Pour devenir “les dieux” sur terre afin de réaliser les
                missions pour lesquelles Dieu nous établit sur terre.
                <br />
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                La Destruction des autels amène une onction pour révéler Dieu
                dans une autre dimension. Une fois qu’on a maîtrisé cette
                onction, elle révèle aussi le monde de spiritualité satanique
                pour que le chrétien prenne ses responsabilités maintenant en
                tant que fils et non comme un enfant.
              </Typography>
            </Container>
          </Grid>
          <Grid item xs={12}>
            <Container>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                La Destruction des autels nous permet à un enfant de Dieu une
                fois libéré, une fois révélé, de mener une véritable vie
                chrétienne épanouie, selon Jean 10:10.
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                La Destruction des Autels nous fait entrer dans les réalités
                spirituelles afin que le peuple de Dieu ne soit plus dans la
                confusion. La vraie réalité, étant essentiellement spirituelle,
                chacun de deux mondes ( divine et diabolique) a une mission
                spécifique dans la vie de l’homme sur terre. <br />
                Celui du diable vise surtout le contrôle et le maintien total
                d'un individu, chrétien ou non dans l'ignorance imposée par lui.
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                C'est ainsi que la destruction des autels ôte à l’église toute
                confusion et lui fait connaître le fonctionnement et le mode
                opératoire du monde spirituel par des enseignements pointus et
                des prières stratégiques en vue de traduire la victoire de la
                croix par des actes concrets dans son quotidien.
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                Subdivisée en plusieurs chapitres ou leçons, dont le premier eut
                lieu le 08 Mars 2008, la Destruction des Autels connaît à ce
                jour plus de 40 chapitres, chacun avec un enseignement
                particulier accompagné d’une puissante onction se manifestant
                avec efficacité. Parmi ses grands thèmes, « Les lois et les
                espaces spirituels », « L’impact néfaste de nos racines », «
                L’hérédité dans le sang », « Les cris du Sang », La logique de
                l’homme esprit et l’homme matière », « Les éléments rampants »,
                « La loi de 4 élément de la nature », « les 4 dimensions du
                ministère de Jésus », « Connaître le seuil de sa spiritualité en
                Jésus », etc.
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                D’autant plus que ce qui est esprit, a plus de puissance et de
                force sur la matière, nul ne peut prétendre combattre et vaincre
                son ennemi s’il n’est suffisamment informé au préalable sur lui.
                ( surtout de son mode opératoire)
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                Dans les situations où le génie humain ne suffirait, les
                chrétiens de ce temps, ont impérativement besoin de l’apport du
                Christ par les enseignements révélateurs de la Destruction des
                Autels; car seule la délivrance demeure essentiellement la clé
                d’une vie chrétienne épanouie. Il faut que le seigneur ouvre
                l'intelligence humaine afin que celle-ci, renouvelée et
                éclairée, discerne enfin le fonctionnement de ces mondes avec
                l’aide d’une onction particulière; ainsi s'accomplira la parole
                qui dit, « si le fils t’affranchit, tu seras réellement libre ».
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                Cette grande dispensation a en son registre plusieurs
                témoignages qui dépassent l’entendement humain, en l’occurrence,
                des personnes nées sans sexe retrouvent leurs identités, des
                morts qui ressuscitent même en contact avec l’effigie de l’Oint
                de Dieu l’apôtre Léopold Mutombo, des bêtes et des objets
                étranges qui quittent des corps physiquement, des paralytiques
                qui lâchent leurs béquilles en plein culte, …pour couronner le
                tout, des milliers d’âmes viennent à Jésus-Christ par la
                conversion et baptême d’eau et du Saint-Esprit.
              </Typography>
            </Container>
          </Grid>
        </Grid>
      </Container>
      <Box sx={{ backgroundColor: "whitesmoke", py: 5 }}>
        <Container>
          <OurShop />
        </Container>
      </Box>
      <HomeContact />
    </div>
  );
};

export default Destruction;
