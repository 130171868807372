import React from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Container,
  alpha,
  useTheme,
  Avatar,
} from "@mui/material";

const HomeCitation = () => {
  return (
    <Container sx={{ p: 5 }}>
      <Grid
        sx={{
          backgroundColor: "#111",
          color: "white",
          borderRadius: 2,
          p: 3,
        }}
        container
        spacing={2}
      >
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          item
          xs={12}
          md={4}
        >
          <Typography textAlign={"center"}>
            <Avatar
              src="/images/photo1.jpeg"
              sx={{ width: 150, height: 150 }}
            />
          </Typography>
        </Grid>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          item
          xs={12}
          md={8}
        >
          <Box>
            <Typography sx={{ fontSize: { xs: 13, md: 16 } }} variant="h5">
              LE PROBLÈME DANS L'AÂME UNE QUESTION DE LA VIE. AUSSI LONGTEMPS
              QU'ELLE VIVRA SELON LES EÉMOTIONS ET LES DEÉSIRS CHARNELS, LA
              DEÉLIVRANCE S'EN EÉLOIGNERA.
            </Typography>
            <Typography sx={{ my: 2, fontSize: { xs: 10, md: 13 } }}>
              L'apôtre Léopold Mutombo Kalombo
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomeCitation;
