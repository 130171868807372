import React, { useState } from "react";
import Breadcrumb from "../../components/includes/Breadcrumb";
import { Box, Container, Grid, Typography } from "@mui/material";
import { books } from "../../data";
import SearchBar from "../../components/includes/SearchBar";

const Shop = () => {
  const [data, setData] = useState(books);
  const [search, setSearch] = useState("");

  const searchFilter = (text) => {
    const newData =
      books &&
      books.filter((item) => {
        const itemData = item.title
          ? item.title.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
    setSearch(text);
    setData(newData);
  };
  return (
    <div>
      <Breadcrumb title={"Shop"} />

      <Container>
        <Box sx={{ my: 5 }}>
          <SearchBar
            searchFilter={searchFilter}
            placeholder={"Rechercher un livre...."}
          />
        </Box>
        <Grid sx={{ mb: 5 }} container spacing={2}>
          {data.map((book) => (
            <Grid item xs={6} md={3}>
              <Box
                sx={{ textDecoration: "none" }}
                component={"a"}
                href={"/shop/" + book.id}
              >
                <Typography>
                  <img src={book.image} alt={book.title} width={"100%"} />
                </Typography>
                <Typography color={"primary"} sx={{ mt: 1 }}>
                  {book.title}
                </Typography>
                <Typography color={"red"}>1,00 USD</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default Shop;
