import React from "react";
import HomeBanner from "../../components/home/Banner";
import HomeAbout from "../../components/home/About";
import HomeMinistries from "../../components/home/Ministries";
import HomeLeadership from "../../components/home/Leadership";
import HomeOeuvres from "../../components/home/Oeuvres";
import HomeMultimedia from "../../components/home/Multimedia";
import HomeCitation from "../../components/home/Citation";
import HomeContact from "../../components/home/Contact";

const Home = () => {
  return (
    <main>
      <HomeBanner />
      <HomeAbout />
      <HomeMinistries />
      <HomeLeadership />
      <HomeOeuvres />
      <HomeMultimedia />
      <HomeCitation />
      <HomeContact />
    </main>
  );
};

export default Home;
