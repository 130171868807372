import { Grid, Typography, alpha, useTheme } from "@mui/material";
import React from "react";

const Breadcrumb = ({ title, content }) => {
  const theme = useTheme();
  return (
    <Grid
      sx={{
        p: { md: 8, xs: 2 },
        pb: 10,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: `linear-gradient(
        to bottom,
         ${alpha(theme.palette.primary.main, 0.3)},
         ${alpha(theme.palette.primary.main, 0.4)}
      ), url('/images/banner.JPG')`,
        height: 300,
      }}
      container
      spacing={2}
    >
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        item
        xs={12}
      >
        <Typography color={"white"} variant="h3" textAlign="center">
          {title}
        </Typography>
        <Typography textAlign="center">{content}</Typography>
      </Grid>
    </Grid>
  );
};

export default Breadcrumb;
