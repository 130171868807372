import React from "react";
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import Breadcrumb from "../../components/includes/Breadcrumb";

const agendaItems = [
  { time: "09:00 AM", event: "Seminaire 1" },
  { time: "10:00 AM", event: "Seminaire 2" },
  { time: "11:00 AM", event: "Seminaire 3" },
  { time: "12:00 PM", event: "Seminaire  4" },
  { time: "01:00 PM", event: "Seminaire 5" },
];

function Agenda() {
  return (
    <Box>
      <Breadcrumb title={"agenda"} />
      <Container sx={{ py: 5 }}>
        <Typography variant="h4" gutterBottom>
          Agenda
        </Typography>
        <List>
          {agendaItems.map((item, index) => (
            <ListItem key={index}>
              <ListItemText primary={item.event} secondary={item.time} />
            </ListItem>
          ))}
        </List>
      </Container>
    </Box>
  );
}

export default Agenda;
