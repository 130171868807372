import React from "react";
import Breadcrumb from "../../components/includes/Breadcrumb";
import { Box, Button, Container, Grid, Typography } from "@mui/material";

const Oeuvres = () => {
  const oeuvres = [
    {
      title: "Les campagnes d'évangélisation",
      image: "/images/evangelisation.jpg",
      content:
        "Gagner un grand nombre à Christ a toujours été le grand fardeau qui anime le cœur de l'Apôtre Léopold MUTOMBO KALOMBO",
    },
    {
      title: "La visitation divine",
      image: "/images/visitation.jpg",
      content:
        "Cette grande dispensation a en son registre plusieurs témoignages qui dépassent l'entendement humain, en l'occurence, des personnes nées sans sexe retrouvent leurs identités, des morts qui ressuscitent même en contact avec l'effigie de l'Oint de Dieu l'Apôtre Léopold MUTOMBO",
    },
    {
      title: "La destruction des autels",
      image: "/images/autels.jpg",
      content:
        "La Destruction des Autels est une grande révélation que le Seigneur Jesus-Christ a aaccordée à son serviteur l'apôtre Léoplod MUTOMBO. Dans ce programme, Dieu a plongé son peuple dans une sorte de triatement prolongé pendant une période.",
    },
  ];
  return (
    <main>
      <Breadcrumb title={"Œuvres"} />
      <Container sx={{ py: 5 }}>
        <Grid container spacing={2}>
          {oeuvres.map((item) => (
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  borderWidth: 1,
                  borderStyle: "double",
                  borderColor: "#eee",
                  borderRadius: 5,
                  p: 5,
                }}
              >
                <Typography>
                  <img src={item.image} width={"100%"} />
                </Typography>
                <Typography variant="h5">{item.title}</Typography>
                <Typography
                  color={"text.secondary"}
                  sx={{ my: 1, fontSize: { xs: 13, md: 16 } }}
                >
                  {item.content}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </main>
  );
};

export default Oeuvres;
