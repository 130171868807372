export const menuList = [
  {
    name: "Accueil",
    href: "/",
  },
  {
    name: "Qui est l'apôtre LMK",
    href: "/a-propos",
  },
  {
    name: "Ministère",
    href: "/ministere",
    items: [
      {
        title: "EMI",
        href: "/emi",
      },
      {
        title: "Destruction des autels",
        href: "/destruction-des-autels",
      },
      {
        title: "Quinzaine et semaine prophétique",
        href: "/quinzaine-et-semaine-prophetique",
      },
      {
        title: "Semaine Ciel Ouvert",
        href: "/semaine-ciel-ouvert",
      },
      {
        title: "Ecole des femmes: Bizaleli ya mwasi",
        href: "/ecole-des-femmes",
      },
      {
        title: "Département des jeunes",
        href: "/departement-des-jeunes",
      },
      {
        title: "Conférence Business Class",
        href: "/conference-business-class",
      },
    ],
  },

  {
    name: "Blog",
    href: "/oeuvres",
  },
  {
    name: "Missions",
    href: "/missions",
  },
  {
    name: "Agenda",
    href: "/agenda",
  },
  {
    name: "Shop",
    href: "/shop",
  },
  {
    name: "Devenir partenaire",
    href: "/devenir-partenaire",
  },
];

export const bottomLinks = [
  {
    name: "Faire un don",
    href: "/",
  },
  {
    name: "Témoignages",
    href: "/",
  },
  {
    name: "Publications",
    href: "/",
  },
  {
    name: "Evénements",
    href: "/",
  },
  {
    name: "A propos",
    href: "/",
  },
  {
    name: "Contact",
    href: "/",
  },
];
