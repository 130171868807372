import React from "react";
import Breadcrumb from "../../components/includes/Breadcrumb";
import { Box, Container, Grid, Typography } from "@mui/material";
import HomeContact from "../../components/home/Contact";
import OurShop from "../../components/includes/Shop";

const Mwasi = () => {
  return (
    <div>
      <Breadcrumb title={"Bizaleli ya mwasi"} />
      <Container sx={{ my: 5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Container>
              <img src="/images/mwasi.JPG" width={"100%"} alt="mwasi" />
            </Container>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container>
              <Typography sx={{ my: 2 }} variant="h3">
                “...On l'appellera Femme…!”{" "}
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                Dans toute sa complexité, l’École « Bizaleli ya Mwasi » qui peut
                se traduire par « Le comportement » ( = éducation, caractère,
                attitude, réflexe…de la femme), est une autre vision de Dieu,
                révélée à son serviteur pour ramener la femme à sa phase
                initiale selon Dieu, tout en épinglant ses droits et ses
                devoirs.
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                Elle est aussi ce cadre d'apprentissage (spirituel, moral et
                psychique …) permettant à la femme de s'exprimer sur ses peurs,
                frustrations, ses défis, son identité et sa face à une société
                masculine ... liés aux expériences acquises au quotidien.
              </Typography>
            </Container>
          </Grid>
          <Grid item xs={12}>
            <Container>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                Elle a donc pour objectifs : <br />
                -d’Encadrer la femme, pour lui permettre de manifester et
                exploiter sa féminité et ses qualités;
                <br />
                -de Permettre à la fiancée de réussir le mariage avant le
                mariage;
                <br />
                -À la femme mariée de se découvrir en tant que aide semblable;
                <br />
                -d’Instruire la jeune fille en brisant les tabous; <br />
                -de Permettre à la femme seule et à la veuve de vivre dans la
                sanctification. Car la femme qui ouvre facilement son cœur ou
                ses Jambes, devient une caserne des démons.
                <br />
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                Appelée donc à être une aide, un renfort, de surcroît sensée
                apporter de la force à l'homme, si la femme aux vues de toute
                cette responsabilité, n'est pas éduquée selon la pensée de Dieu,
                l'on peut tout de suite déduire, l'état défaillant de toute la
                charpente sociétale, socle d'une famille.
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                En somme, l'école « Bizaleli ya Mwasi » réunissant toute la
                junte féminine quel que soit son rang, son statut, sa race et
                même sa religion…, existe pour récupérer cette femme exposée au
                flux des courants féministes dont erronés pour la plupart, et la
                replacer dans sa position originelle; afin de la permettre
                d’apporter une contribution considérable dans le développement
                de sa personne, de la société, de l’épanouissement dans son
                ménage et d’être une bénédiction pour son entourage.
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                Notons que cette école a connu plusieurs éditions à succès de
                conventions et de matinées “Bizaleli” à Kinshasa, à Lubumbashi
                et à Brazzaville.
              </Typography>
            </Container>
          </Grid>
        </Grid>
      </Container>
      <Box sx={{ backgroundColor: "whitesmoke", py: 5 }}>
        <Container>
          <OurShop />
        </Container>
      </Box>
      <HomeContact />
    </div>
  );
};

export default Mwasi;
