import React from "react";
import { Typography, Grid, Button, Container, TextField } from "@mui/material";

const HomeContact = () => {
  return (
    <Container sx={{ my: { md: 5, xs: 3 } }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <form>
            <Typography sx={{ my: 2 }} variant="h4">
              Envoyez un message à L'Apôtre
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField label="Nom" fullWidth variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Email" fullWidth variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  rows={4}
                  label="Message"
                  placeholder="Entrez votre message..."
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Button fullWidth variant="contained" type="submit">
                  Envoyer
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item sx={{ display: { xs: "none", md: "block" } }} md={6}>
          <Typography textAlign={"center"}>
            <img
              src="/images/photo3.jpeg"
              width={400}
              style={{ borderRadius: 8 }}
            />
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomeContact;
