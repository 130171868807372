import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import NavItem from "./NavItem";
import { menuList } from "../menuList";
import { Drawer, Typography, useTheme } from "@mui/material";
import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";
import CallIcon from "@mui/icons-material/Call";

const SidebarNav = ({ onClose, open, variant }) => {
  const theme = useTheme();
  const user = localStorage.getItem("userID");

  return (
    <Drawer
      anchor="left"
      onClose={() => onClose()}
      open={open}
      variant={variant}
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: 280,
          backgroundColor: "black",
        },
      }}
    >
      <Box
        sx={{
          height: "100%",
          padding: 1,
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
            width={1}
            paddingX={2}
            paddingY={1}
          >
            <Typography
              textAlign={"center"}
              component="a"
              sx={{ mr: 0.5 }}
              href={"/"}
            >
              <img src="/images/me.jpg" alt="logo" width={150} />
            </Typography>
          </Box>
          <Box paddingX={2} paddingY={2}>
            {menuList.map((page) =>
              page.items ? (
                <Box sx={{ ml: 1, fontSize: 15 }}>
                  <NavItem title={page.name} items={page.items} />
                </Box>
              ) : (
                <Button
                  component="a"
                  href={page.href}
                  sx={{
                    display: "block",
                    color: "white",
                    fontSize: 13,
                    "&:hover": {
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  {page.name}
                </Button>
              )
            )}

            <Box sx={{ my: 2 }}>
              <Button
                color="secondary"
                component="a"
                href="https://vpos.flexpaie.com/pay/advanced/QU1FTg=="
                size="small"
                variant="contained"
                sx={{ px: 4 }}
                fullWidth
              >
                Faire un don
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
