import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { footerData } from "../../data";
import { bottomLinks, menuList } from "../header/menuList";

const Footer = () => {
  const theme = useTheme();

  return (
    <main>
      <Box sx={{ backgroundColor: "black", py: 3 }}>
        <Container sx={{ p: 3 }}>
          <Grid
            container
            sx={{
              backgroundColor: "#222",
              color: "white",
              p: 3,
              borderRadius: 5,
              my: 3,
            }}
            spacing={2}
          >
            <Grid item xs={12} md={6}>
              <Typography sx={{ fontSize: { xs: 16, md: 20 } }} variant="h4">
                Abonnez vous à la newsletter pour obtenir des nouvelles.
              </Typography>
            </Grid>
            <Grid container item xs={12} md={6}>
              <TextField
                sx={{ borderColor: "white" }}
                label="E-mail"
                fullWidth
                variant="filled"
              />
              <Typography textAlign={"right"}>
                <Button sx={{ my: 1, fontSize: 13 }} variant="contained">
                  S'abonner
                </Button>
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography sx={{ display: { xs: "none", md: "flex" } }}>
                <img src={"/images/me.jpg"} alt="logo" width={"70%"} />
              </Typography>
              <Typography
                textAlign={"center"}
                sx={{ display: { xs: "flex", md: "none" } }}
              >
                <img src={"/images/me.jpg"} alt="logo" width="30%" />
              </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              <Typography sx={{ color: "white" }} variant="h6">
                Menu
              </Typography>
              {menuList.map((item) => (
                <Button
                  component="a"
                  href={item.href}
                  sx={{
                    textTransform: "Capitalize",
                    color: "white",
                    fontSize: 15,
                    display: "block",
                    p: 0,
                    "&:hover": {
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  {item.name}
                </Button>
              ))}
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography sx={{ color: "white" }} variant="h6">
                Liens
              </Typography>
              {bottomLinks.map((item) => (
                <Button
                  component="a"
                  href={item.href}
                  sx={{
                    textTransform: "Capitalize",
                    color: "white",
                    fontSize: 15,
                    display: "block",
                    p: 0,
                    "&:hover": {
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  {item.name}
                </Button>
              ))}
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography sx={{ color: "white" }} variant="h6">
                Suivez-nous
              </Typography>
              <Box>
                {footerData.communnity.socials.map((social) => (
                  <IconButton
                    component="a"
                    href={social.link}
                    sx={{
                      "&:hover": {
                        color: "white",
                        fontSize: 25,
                      },
                    }}
                  >
                    {social.icon}
                  </IconButton>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={{ backgroundColor: theme.palette.primary.main, mt: 0, p: 2 }}>
        <Typography
          textAlign="center"
          color={"white"}
          sx={{
            fontSize: 13,
            my: 2,
          }}
        >
          Copyright &copy; {new Date().getFullYear()}, Léopold Mutombo Kalombo
          Ministries. Tous droits réservés.
        </Typography>
      </Box>
    </main>
  );
};

export default Footer;
